import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';

export const checkMail = ({huk, vrcd}, authenticated) => {
  return {
    types: ['CHECK_MAIL_START', 'CHECK_MAIL_COMPLETE', 'CHECK_MAIL_ERROR'],
    config: {
      method: 'POST',
      url: 'email/verify/',
      data: {
        encoded_user_pk: huk,
        key: vrcd,
      },
    },

    shouldCallAPI: ({auth: {loading}}) => !loading && huk && vrcd,
    callback: (result) => {
      if (result) {
        notification({
          id: 'email_verified',
          type: 'success',
        });
        navigate('/tracks');
        if (!authenticated) {
          navigate('/auth/login');
        }
      } else {
        // const message = Object.values(resultData.response.data)
        //   .map(value => value.toString())
        //   .toString();
        // sendNotif({message});
        notification({id: 'email_not_verified'});
        navigate('/auth/verify-email');
      }
    },
  };
};

export const sendVerificationMail = (email) => ({
  types: [
    'SEND_VERIFICATION_EMAIL_START',
    'SEND_VERIFICATION_EMAIL_COMPLETE',
    'SEND_VERIFICATION_EMAIL_ERROR',
  ],
  config: {
    method: 'POST',
    url: 'send-verification-email/',
    data: {
      email,
    },
  },
  shouldCallAPI: ({auth: {loading}}) => !loading && email,
  callback: (result, resultData) => {
    if (result) {
      notification({
        id: 'verification_email_sent_success',
        type: 'success',
      });
    } else if (
      resultData &&
      resultData.response &&
      resultData.response.data === 'Your email is already verified'
    ) {
      notification({
        id: 'email_already_verified',
        type: 'success',
      });
      // We want the back button ton be tracks
      navigate('/tracks');
      navigate('/auth/login');
    } else {
      notification({
        id: 'verification_email_sent_failure',
      });
    }
  },
});
