import parseURLSearch from 'core/libs/helpers/parseURLSearch';
import {navigate} from 'gatsby';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {checkMail} from '../actions/checkMail';

const {PARTNER} = process.env;

const ConfirmationEmail = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  useEffect(() => {
    const {
      authenticated,
      dispatch,
      location: {search},
    } = props;
    const params = parseURLSearch(search);
    const {huk, vrcd} = params;
    if (huk && vrcd) {
      dispatch(checkMail(parseURLSearch(search), authenticated));
    } else {
      navigate('/auth/verify-email');
    }
  }, [props]);
  return (
    <Container className="mt5-ns mb4-ns mw6-ns bg-white pa3 ph5-ns">
      <Head
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_email_confirmation'
              : 'partner_helmet_email_confirmation',
        })}
      />
      <h3 className="f3 fw6 mb3">
        <FormattedMessage id="email_confirmation_title" />
      </h3>
      <div className="tc">
        <LoadingIndicator />
      </div>
    </Container>
  );
};

ConfirmationEmail.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const enhance = connect(({auth: {authenticated}}) => ({authenticated}));

export default enhance(ConfirmationEmail);
